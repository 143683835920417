//完成对新闻模块数据的网络请求
import axios from "../network";
//导入配置选项
import datas from "./config";
//封装新闻模块所有的网络请求
function startNetWork(config) {
  return axios({
    ...config,
  });
}
//为每一个数据源提供getter方法帮助用户获取想要的数据
const newObj = {};
//遍历数据源
for (let key in datas) {
  Object.defineProperty(newObj, key, {
    async get() {
      let val = datas[key](this.meta ? this.meta : {});
      this.meta = null;
      //开始网络请求
      let result = await startNetWork(val);
      return result;
    },
  });
}
export default newObj;
