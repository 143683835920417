import Vue from "vue";
import store from "./store";
//创建一个Vue对象
import {mapState, mapMutations} from "vuex";
const vm = new Vue({
    store,
    computed:{
        ...mapState(['loading'])
    },
    methods:{
        ...mapMutations(['changeLoading'])
    }
});
export default vm;