import { IP2 } from "../ipconfig.js";
const pinglun = {
    addXMPL(meta){
      // 添加项目评论
      return {
         url:IP2+"/exam/xm_plM/",
         method:"post",
         data:{...meta, date:getDate()}
      }
    },
    getPingLun(meta){
      //获取评论
      return {
        url:IP2+"/exam/xm_plM/",
        params:{...meta}
      } 
    },
    deletPingLun(meta){
      return {
        url:IP2+"/exam/xm_plO/"+meta.id+"/",
        method:"delete"
      }
    }
}
function getDate(){
  let date = new Date();
  let year = date.getFullYear();
  let month = date.getMonth() < 9 ? '0'+(date.getMonth()+1):(date.getMonth()+1);
  let day = date.getDate() < 10 ? '0'+date.getDate():date.getDate();
  return `${year}-${month}-${day}`;
}
export default pinglun;