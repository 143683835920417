//导入axios
import axios from "axios";
import bus from "../bus";
//创建axios网络请求对象
const newAxios = axios.create({
    timeout:600000
});  
//拦截器, 请求拦截器
newAxios.interceptors.request.use(function(config){
    // 开始显示loading
    bus.changeLoading(true);
    //当网络请求成功发送时对应的拦截 
    return config;
}, function(err){
    // 当网络请求成功发送失败时对应的拦截
    console.log(err);
});
// 响应拦截器
newAxios.interceptors.response.use(function(response){
    // 隐藏loading
    bus.changeLoading(false); 
    // 当网络请求成功接收到数据时对应的拦截
    return response;
}, function(err){
    // 当网络请求未接到响应时对应的拦截
    console.log(err);
});
export default newAxios;