<template>
  <div class="jindu" v-loading="loading">
    <div class="liuji">
        <i class="el-icon-circle-plus-outline primary" @click="clickAction"></i>
    </div>
    <el-table
      :data="tableData"
      border
      style="width: 100%"
      max-height="500"
    >
      <el-table-column prop="className" label="班级" width="180">
      </el-table-column>
      <el-table-column prop="teacherName" label="带班讲师" width="180">
      </el-table-column>
      <el-table-column
        prop="jieDuanName"
        width="180"
        label="课程阶段"
      ></el-table-column>
      <el-table-column prop="keChengName" label="课程名"></el-table-column>
      <el-table-column prop="mryk" label="每日一考"></el-table-column>
      <el-table-column
        width="80"
        label="转入人数"
      >
         <template slot-scope="scope">
            <el-link target="_blank" @click="skipCheck('in', scope.row)">{{scope.row.in_num}}</el-link>
        </template>
      </el-table-column>
      <el-table-column
        width="80"
        label="转出人数"
      >
        <template slot-scope="scope">
            <el-link target="_blank" @click="skipCheck('out', scope.row)">{{scope.row.out_num}}</el-link>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "HomeLive",
  data() {
    return {
      tableData: [],
      jindu: {},
    };
  },
  computed: {
    ...mapState(["loading"]),
  },
  methods: {
    clickAction() {
       let classes = [];
       for(let i in this.tableData){
            classes.push(this.tableData[i].className);
       }
      //留级处理
      this.$router.push({name:"LiuJi", query:{classNames:classes}});
    },
    closeLiuji() {
      this.show = false;
    },
    skipCheck(kind, row){
        //查看留级记录
        this.$router.push({name:"LiuJiCheck", query:{params:{...row, kind}}}); 
    }
  },
  async created() {
    let res = await this.$datas.home;
    let res1 = await this.$datas.jindu;
    this.jindu = res1.data.msg.data;
    let datas = res.data.msg.data;
    for (let i in datas) {
      if (this.jindu[datas[i].className]) {
        this.$datas.meta = { ...this.jindu[datas[i].className][0] };
        let res2 = await this.$datas.jieduan;
        this.tableData.push({
          ...datas[i],
          teacherName: this.jindu[datas[i].className][0].user_Name,
          mryk: res2.data.msg.data[0].keChengName,
        });
      } else {
        this.tableData.push({
          ...datas[i],
          mryk: "暂无信息",
        });
      }
    }
    let res2 = await this.$datas.getLiuJiClass;
    datas = res2.data;
    for(let i in datas){
        for(let j in this.tableData){
            if(this.tableData[j].className == datas[i].className){
                this.tableData.splice(j, 1, {...this.tableData[j], in_num:datas[i].in_num, out_num:datas[i].out_num});
                break;
            }
        }
    }
    this.tableData.sort((item1, item2)=>{
        let className1 = item1.className.replace(/[A-Z]/g, "");
        let className2 = item2.className.replace(/[A-Z]/g, "");
        return className2 - className1;
    });
  },
};
</script>
<style lang="less" scoped>
.jindu {
  .primary {
    color: #555;
    cursor: pointer;
    font-size:23px;
    &:hover {
        color: #aaff;
    }
  }
  .liuji {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
    box-sizing: border-box;
  }
}
</style>
