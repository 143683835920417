import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
export default new Vuex.Store({
    state: {
        activeList: "1-0",
        loading: false,
        tk_index: 0,
        reload:false,
        role:sessionStorage.getItem('role')
    },
    mutations: {
        changeActiveList(state, index) {
            state.activeList = index;
        },
        changeLoading(state, loading) {
            state.loading = loading;
        },
        changeTkIndex(state, index) {
            state.tk_index = index;
        },
        changeReload(state, load){
            state.reload = load;
        },
        changeRole(state, username){
           state.role = username == "admin" ? 1:0; 
           sessionStorage.setItem('role', state.role);
        }
    },
    actions: {},
    modules: {},
});