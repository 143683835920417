import Vue from "vue";
import VueRouter from "vue-router";
import HelloWord from "../views/Home.vue";
import Login from "../views/Login.vue";
import vm from "../bus";
Vue.use(VueRouter);
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
    return originalReplace.call(this, location).catch((err) => err);
};
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};
const routes = [{
        path: "/",
        name: "Login",
        component: Login,
    },
    {
        path: "/home",
        name: "Home",
        component: HelloWord,
        meta: { index: "1-0" },
    },
    {
        path: "/mryk",
        name: "MRYK",
        component: () =>
            import ("../views/MRYK.vue"),
        meta: { index: "2-0" },
        children: [{
                path: "tc",
                name: "TestCase",
                component: () =>
                    import ("../views/TestCase/TestCase.vue"),
                meta: { index: "2-1" },
            },
            {
                path: "ss",
                name: "StudentScores",
                component: () =>
                    import ("../views/StudentScores/StudentScores.vue"),
                meta: { index: "2-2" },
            },
        ],
    },
    {
        path: "/cv",
        name: "CommonView",
        component: () =>
            import ("../views/CommonView/CommonView"),
        children: [{
                path: "cc/:course_Id/:class_Id",
                name: "ClassCase",
                component: () =>
                    import ("../views/CommonView/ClassCase.vue"),
            },
            {
                path: "ct/:class_Id/:knowexam_Id",
                name: "ClassTest",
                component: () =>
                    import ("../views/CommonView/ClassTest.vue"),
            },
            {
                path: "ct/:jd",
                name: "JDScore",
                component: () =>
                    import ("../views/CommonView/StuJDScore.vue"),
            },
            {
                path: "score",
                name: "StudentScore",
                component: () =>
                    import ("../views/CommonView/StudentScore.vue"),
            },
            {
                path: "qsi",
                name: "QustionInfo",
                component: () =>
                    import ("../views/CommonView/QuestionInfo.vue"),
            },
            {
                path: "bm",
                name: "BaoMing",
                component: () =>
                    import ("../views/CommonView/BaoMing.vue"),
            },
            {
                path: "zkcj/:className",
                name: "ZhouKaoScore",
                component: () =>
                    import ("../views/StudentScores/ZKCJ.vue"),
            },
            {
                path: "zkcj_update/:className",
                name: "ZhouKaoScoreUpdate",
                component: () =>
                    import ("../views/StudentScores/ZKScoreCheck.vue"),
            },
            {
                path: "zkcj_check/:className",
                name: "ZhouKaoScoreCheck",
                component: () =>
                    import ("../views/StudentScores/StudentScores.vue"),
            },
            {
                path: "showScore",
                name: "ShowScore",
                component: () =>
                    import ("../views/CommonView/ShowScore.vue"),
            },
            {
                path: "jd_score",
                name: "JDChengJI",
                component: () =>
                    import ("../views/StudentScores/JDScore.vue"),
            },
            {
                path: "liuji_check",
                name: "LiuJiCheck",
                component: () =>
                    import ("../views/CommonView/LiuJiCheck.vue"),
            },
        ],
    },
    {
        path: "/zk",
        name: "ZhouKao",
        component: () =>
            import ("../views/ZhouKao.vue"),
        meta: { index: "3-0" },
        children: [{
                path: "tk_check",
                name: "TKCheck",
                component: () =>
                    import ("../views/TiKu/Check.vue"),
                meta: { index: "3-1" },
            },
            {
                path: "tk_add",
                name: "TKAdd",
                component: () =>
                    import ("../views/TiKu/TKAdd.vue"),
                meta: { index: "3-2" },
            },
            {
                path: "tk_score",
                name: "TKScore",
                component: () =>
                    import ("../views/TiKu/TKScoreCheck.vue"),
                meta: { index: "3-3" },
                children: [{
                        path: "score_check",
                        name: "ScoreCheck",
                        component: () =>
                            import ("../views/TiKu/ScoreCheck.vue"),
                    },
                    {
                        path: "pl_check_score",
                        name: "PLCheckScore",
                        component: () =>
                            import ("../views/TiKu/PLAddScore.vue"),
                    },
                ],
            },
            {
                path: "tk_bukao",
                name: "BuKaoMingDan",
                component: () =>
                    import ("../views/TiKu/BuKaoMingDan.vue"),
            },
            // {
            //     path: "tk_course",
            //     name: "TiKuCourse",
            //     component: () =>
            //         import ("../views/TiKu/PLAddCourse.vue"),
            // },
        ],
    },
    {
        path: "/sx",
        name: "Shixun",
        component: () =>
            import ("../views/ShiXun.vue"),
        meta: { index: "4-0" },
        children: [{
                path: "add",
                name: "ShixunAdd",
                component: () =>
                    import ("../views/Shixun/ShixunAdd.vue"),
                meta: { index: "4-2" },
            },
            {
                path: "addR",
                name: "SXResult",
                component: () =>
                    import ("../views/Shixun/SXResultAdd.vue"),
                meta: { index: "4-3" },
            },
            {
                path: "checkR",
                name: "SXResultCheck",
                component: () =>
                    import ("../views/Shixun/SXResultCheck.vue"),
                meta: { index: "4-1" },
            },
            {
                path: "bmtj",
                name: "BMTJ",
                component: () =>
                    import ("../views/Shixun/BMTJ.vue"),
                meta: { index: "4-4" },
            },
        ],
    },
    {
        path: "/pdf",
        name: "PDF",
        component: () =>
            import ("../views/TiKu/pdf.vue"),
    },
    {
        path: "/xm",
        name: "XM",
        component: () =>
            import ("../views/XiangMu.vue"),
        meta: { index: "5-0" },
    },
    {
        path: "/check_xm",
        name: "CheckXM",
        component: () =>
            import ("../views/Xiangmu/checkXM.vue"),
        meta: { index: "5-0" },
    },
    {
        path: "/stuList",
        name: "StuList",
        component: () =>
            import ("../views/Xiangmu/stuList.vue"),
        meta: { index: "5-0" },
    },
    {
        path: "/kecheng",
        name: "KeCheng",
        component: () =>
            import ("../views/KeCheng.vue"),
        meta: { index: "6-0" },
    },
    {
        path: "/zuoye_list/:id",
        name: "ZuoYeList",
        component: () =>
            import ("../views/KeCheng/zuoyeList.vue"),
        meta: { index: "6-0" },
    },

    //口碑
    {
        path: "/koubei",
        name: "KouBei",
        component: () =>
            import ("../views/KouBei.vue"),
        meta: { index: "7-0" },
    },
    {
        path: "/koubei_add",
        name: "KouBeiAdd",
        component: () =>
            import ("../views/KouBei/koubeiAdd.vue"),
        meta: { index: "7-0" },
    },
    {
        path: "/bj_koubei_info",
        name: "BJKBInfo",
        component: () =>
            import ("../views/KouBei/BJKBInfo.vue"),
        meta: { index: "7-0" },
    },
    {
        path: "/cv1",
        name: "CommonView1",
        component: () =>
            import ("../views/CommonView/CommonView1"),
        children: [
            {
                path: "liuji",
                name: "LiuJi",
                component: () =>
                    import ("../views/CommonView/LiuJiDeal.vue"),
            },
        ],
    },
    {
        path:"/jieduan_duoye",
        name:"JieDuanZuoYe",
        component:()=>import("../views/KeCheng/jieduan_zuoye.vue")
    },
    {
        path:"/jianli",
        name:"JianLi",
        component:()=>import("@/views/JianLi.vue"),
        meta: { index: "8-0" },
    },
    {
        path:"/jianli/add",
        name:"JianLiAdd",
        component:()=>import("@/views/JianLi/AddJianLi.vue")
    },
    {
        path:"/update_jili_data",
        name:"UpdateData",
        component:()=>import("@/views/JianLi/updateData.vue")
    }
];
const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});
router.beforeEach(function(to, from, next) {
    console.log(to, from);
    if (localStorage.getItem("time")) {
        let date = new Date();
        let timestr = date.getTime();
        if (timestr - localStorage.getItem("time") >= 3600 * 1000) {
            localStorage.removeItem("login");
        }
    }
    if (!localStorage.getItem("login") && to.name != "Login") {
        router.replace({ name: "Login" });
    }
    if (to.meta.index) {
        vm.$store.commit("changeActiveList", to.meta.index);
    }
    next();
});
export default router;