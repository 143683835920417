<template>
  <div class="login">
    <h3 class="title">河南蓝鸥科技有限公司</h3>
    <div class="login_content">
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="用户名">
          <el-input v-model="form.user"></el-input>
        </el-form-item>
        <el-form-item label="密码">
          <el-input v-model="form.password" show-password></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">登录</el-button>
        </el-form-item>
      </el-form>
    </div>
    <a href="https://beian.miit.gov.cn/" target="__blank" class="foot">备案号：豫ICP备2023010203号-1</a>
  </div>
</template>
<script>
import {mapMutations} from "vuex";
export default {
  name: "Login",
  data() {
    return {
      form: {
        user: "",
        password: "",
      },
    };
  },
  methods: {
    ...mapMutations(['changeRole']),
    async onSubmit() {
      this.$datas.meta = { ...this.form };
      let res = await this.$datas.login;
      if (res.status == 200) {
        alert("登陆成功");
        localStorage.setItem("login", true);
        let date = new Date();
        localStorage.setItem("time", date.getTime());
        this.changeRole(this.form.user);
        if(this.form.user == "admin"){
            this.$router.replace({ name: "Home" });
        }else{
            this.$router.replace({ name: "JianLi" });
        }
      } else {
        alert("账号密码错误,请联系超级管理员");
      }
    },
  },
};
</script>
<style lang="less" scoped>
.login {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 20000;
  background: url("../assets/3.jpg") no-repeat;
  background-size: cover;
  background-position: center center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  .login_content {
    width: 40%;
    margin-top: 50px;
  }
  .foot {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
    padding: 20px;
    color: #555;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  .title {
    position: absolute;
    left: 12%;
    top: 50px;
    width: 80%;
    text-align: center;
    padding: 30px 0;
    font-size: 50px;
    color: #555;
  }
}
</style>
