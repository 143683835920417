import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import axios from "axios";
import "./common/index.js";
import { IP1, IP2 } from "./common/ipconfig.js";
// 导入网络请求
import datas from "./common/api/news";
import "./common/fonts/iconfont.css";
Vue.config.productionTip = false;
Vue.prototype.$axios = axios;
Vue.prototype.$IP = { IP1, IP2 };
Vue.prototype.$datas = datas;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

