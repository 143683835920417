// 导入公共IP
import { IP2 } from "../ipconfig.js";
const jianli = {
    get_jianli_compony(){
        return {
            url:IP2+"/app1/jianli_gsM/"
        }
    },
    get_jianli_jishu(){
        return {
            url:IP2+"/app1/jianli_jishuM/"
        }
    },
    addJianLiNeiRong(meta){
        let url = meta.radio == 0 ? "/app1/jishu_desM/":(meta.radio == 1 ? "/app1/jianli_gsM/":"/app1/jianli_xmM/");
        return {
            url:IP2+url,
            method:"post",
            data:meta
        }
    },
    get_jianliDatas(meta){
        let url = meta.radio == 0 ? "/app1/jishu_desM/":(meta.radio == 1 ? "/app1/jianli_gsM/":"/app1/jianli_xmM/");
        return {
            url:IP2+url
        }
    },
    update_jishu(meta){
        return {
            url:IP2+"/app1/jishu_desO/"+meta.id+"/",
            method:"put",
            data:meta
        }
    },
    delete_jishu(meta){
        return {
            url:IP2+"/app1/jishu_desO/"+meta.id+"/",
            method:"delete"
        }
    },
    update_gs(meta){
        return {
            url:IP2+"/app1/jianli_gsO/"+meta.id+"/",
            method:"put",
            data:meta
        } 
    },
    delete_gs(meta){
        return {
            url:IP2+"/app1/jianli_gsO/"+meta.id+"/",
            method:"delete"
        }
    },
    update_xm(meta){
        return {
            url:IP2+"/app1/jianli_xmO/"+meta.id+"/",
            method:"put",
            data:meta
        } 
    },
    delete_xm(meta){
        return {
            url:IP2+"/app1/jianli_xmO/"+meta.id+"/",
            method:"delete"
        } 
    }
};
export default jianli;